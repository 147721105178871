export const environment = {
  production: false,
  apiUrl: 'https://www.games.back.act-eng-app.co/api',
  apiUrlImg: 'https://www.games.back.act-eng-app.co',
  // apiUrl: 'http://games.back.act-eng-app.co/api',
  // apiUrlImg: 'http://games.back.act-eng-app.co',
  pusher: {
    key: 'english-games',
    cluster: 'mt1',
    wsHost: 'www.games.back.act-eng-app.co',
    wsPort: 6002,
  },
  minBingoSize: 2,
};

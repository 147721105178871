import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class TimerGameComponentService {
  private _seconds: number = 0;
  set seconds(seconds: number) {
    this._seconds = seconds;
  }

  get seconds(): number {
    return this._seconds;
  }
  secondsSub: BehaviorSubject<number> = new BehaviorSubject<number>(this.seconds);

  timerSub!: Subscription;

  constructor() {}

  startTimer(): void {
    if (!this.timerSub?.closed) {
      this.timerSub?.unsubscribe();
    }
    const deltaMilliseconds = dayjs().get('millisecond');
    this.timerSub = timer(1000 - deltaMilliseconds, 1000)
      .pipe(untilDestroyed(this))
      .subscribe((_) => {
        if (this.seconds <= 0) {
          this.secondsSub.next(0);
          return;
        }
        this.seconds -= 1;
        this.secondsSub.next(this.seconds);
      });
  }
}
